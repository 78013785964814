// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Map_Map__qDOKf {\n    height: 500px;\n    width: 80%;\n}", "",{"version":3,"sources":["webpack://src/styles/Map.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;AACd","sourcesContent":[".Map {\n    height: 500px;\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Map": "Map_Map__qDOKf"
};
export default ___CSS_LOADER_EXPORT___;
