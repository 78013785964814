// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Asset_Asset__1dBcX {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-height: 20px;\n    color: rgb(136, 0, 0);\n  }\n\n  .Asset_Asset__1dBcX:hover {\n    cursor: pointer;\n  }\n\n  .Asset_Asset2__yrB3S:hover {\n    cursor: pointer;\n  }\n    \n\n  .Asset_Asset2__yrB3S {\n    /* display: flex;\n    flex-direction: column; */\n    /* justify-content: space-between; */\n    align-items: center;\n    min-height: 20px;\n    color: rgb(136, 0, 0);\n  }", "",{"version":3,"sources":["webpack://src/styles/Asset.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;;EAGA;IACE;6BACyB;IACzB,oCAAoC;IACpC,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;EACvB","sourcesContent":[".Asset {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-height: 20px;\n    color: rgb(136, 0, 0);\n  }\n\n  .Asset:hover {\n    cursor: pointer;\n  }\n\n  .Asset2:hover {\n    cursor: pointer;\n  }\n    \n\n  .Asset2 {\n    /* display: flex;\n    flex-direction: column; */\n    /* justify-content: space-between; */\n    align-items: center;\n    min-height: 20px;\n    color: rgb(136, 0, 0);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Asset": "Asset_Asset__1dBcX",
	"Asset2": "Asset_Asset2__yrB3S"
};
export default ___CSS_LOADER_EXPORT___;
