// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PostsPage_SearchBar__1T8bC input {\n    border-radius: 20px;\n    color: #242a3d;\n    padding-left: 40px;\n    margin-bottom: 16px;\n  }\n\n.PostsPage_Info__2J-gB {\n  color: #000;\n}\n\n.PostsPage_Top__3QU0a {\n  background-color: #1ea7ae;\n  color: #fff;\n  border: 1px solid #fff\n}\n\n.PostsPage_Top__3QU0a:hover {\n  background-color: #22b9c1;\n  color: #fff;\n}\n\n.PostsPage_Top__3QU0a i:hover {\n  color: #fff;\n}\n\n.PostsPage_Title__165no {\n  background-color: #fff;\n}\n\n@media screen and (max-width: 600px) {\n  .PostsPage_Titlelist__5qwXC {\n    font-size: .75rem ;\n  }\n}", "",{"version":3,"sources":["webpack://src/styles/PostsPage.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;AAEF;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX;AACF;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".SearchBar input {\n    border-radius: 20px;\n    color: #242a3d;\n    padding-left: 40px;\n    margin-bottom: 16px;\n  }\n\n.Info {\n  color: #000;\n}\n\n.Top {\n  background-color: #1ea7ae;\n  color: #fff;\n  border: 1px solid #fff\n}\n\n.Top:hover {\n  background-color: #22b9c1;\n  color: #fff;\n}\n\n.Top i:hover {\n  color: #fff;\n}\n\n.Title {\n  background-color: #fff;\n}\n\n@media screen and (max-width: 600px) {\n  .Titlelist {\n    font-size: .75rem ;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBar": "PostsPage_SearchBar__1T8bC",
	"Info": "PostsPage_Info__2J-gB",
	"Top": "PostsPage_Top__3QU0a",
	"Title": "PostsPage_Title__165no",
	"Titlelist": "PostsPage_Titlelist__5qwXC"
};
export default ___CSS_LOADER_EXPORT___;
