/* Component in IndexShots to display each the IndexShot
 * Contains a link to IndexShotsFullSize to view the Image full size
 * Contains the IndexShotEdit component */
import React, { useState } from "react"
import { Col, Image, Row } from "react-bootstrap"
import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"
import { axiosInstance, axiosReq } from "../../api/axiosDefaults"
import { PostDropdown } from "../../components/PostDropdown"
import styles from "../../styles/Characters.module.css"
import IndexShotEdit from "./IndexShotEdit"
import useHostName from "../../hooks/useHostName"
import { CLIENT_PROGRAM_HOSTNAME } from "../../utils/config"

const IndexShot = (props) => {
  const host = useHostName()
  const [showEdit, setShowEdit] = useState(false)
  const { id, number, content, image, shot, setHasOrder, setIndexShots } = props

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleDelete = async () => {
    try {
      if (host === CLIENT_PROGRAM_HOSTNAME) {
        await axiosReq.delete(`/indexshots/${id}/`)
        setHasOrder(true)
      } else {
        await axiosInstance.delete(
          `${localStorage.getItem("projectSlug")}/indexshots/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
          }
        )
        setHasOrder(true)
      }
    } catch (err) {}
  }

  return (
    <div>
      <Card className={`text-center p-0 ${styles.Top}`}>
        <Card.Header className={`py-0 ${styles.Top}`}>
          {/* className={` ${styles.Titledetail }`} */}
          <Row>
            <Col className="mx-0 px-0" xs={1}></Col>
            <Col
              xs={10}
              className="mx-0 px-0 
                    d-flex align-items-center justify-content-center"
            >
              <h5 className={` ${styles.Titlelist}`}>{number}</h5>
            </Col>
            <Col xs={1} className="text-center mx-0 px-0 py-0">
              <PostDropdown
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </Col>
          </Row>
        </Card.Header>
        <Link
          to={`/${localStorage.getItem(
            "projectSlug"
          )}/indexshots/fullsize/${id}`}
        >
          <Card.Body className={`text-center p-0 `}>
            <Row>
              <Col className="text-center mt-0" xs={12}>
                {image && (
                  <>
                    <div className="px-0 mb-0">
                      <Image
                        className={styles.Images}
                        src={image}
                        alt="image"
                        height="200"
                      />
                    </div>
                  </>
                )}
                {content && (
                  <>
                    <p>{content} </p>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Link>
      </Card>
      {!showEdit ? (
        ""
      ) : (
        <IndexShotEdit
          setIndexShots={setIndexShots}
          shot={shot}
          id={id}
          setShowEdit={setShowEdit}
          setHasOrder={setHasOrder}
        />
      )}
    </div>
  )
}
export default IndexShot
