// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleSceneItem_BlueBack__3-oHL {\n  height: 20px;\n}\n\n.ScheduleSceneItem_White__vF86K {\n  background-color: #fff;\n}\n\n.ScheduleSceneItem_BoldTitle2__1jhqS {\n  font-weight: 700;\n  border-right : 3px solid #fafafa;\n  background-color: #d6fcff;\n}\n\n.ScheduleSceneItem_CharactersTitle__2EgBc {\n  color: rgb(40, 189, 189);\n  font-style: italic;\n}\n\n.ScheduleSceneItem_Item__3Wh1N {\n    background-color: #1ea7ae;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n.ScheduleSceneItem_Item__3Wh1N:hover {\n    background-color: #3b444b;\n} \n\n.ScheduleSceneItem_ItemNu__2x_Vh {\n  background-color: #55c8ce;\n  /* background-color: #1ea7ae; */\n  color: #fff;\n} \n\n.ScheduleSceneItem_Italic__oe_FI {\n  font-style: italic;\n}", "",{"version":3,"sources":["webpack://src/styles/ScheduleSceneItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;EACjB;;AAEF;IACI,yBAAyB;AAC7B;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".BlueBack {\n  height: 20px;\n}\n\n.White {\n  background-color: #fff;\n}\n\n.BoldTitle2 {\n  font-weight: 700;\n  border-right : 3px solid #fafafa;\n  background-color: #d6fcff;\n}\n\n.CharactersTitle {\n  color: rgb(40, 189, 189);\n  font-style: italic;\n}\n\n.Item {\n    background-color: #1ea7ae;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n.Item:hover {\n    background-color: #3b444b;\n} \n\n.ItemNu {\n  background-color: #55c8ce;\n  /* background-color: #1ea7ae; */\n  color: #fff;\n} \n\n.Italic {\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BlueBack": "ScheduleSceneItem_BlueBack__3-oHL",
	"White": "ScheduleSceneItem_White__vF86K",
	"BoldTitle2": "ScheduleSceneItem_BoldTitle2__1jhqS",
	"CharactersTitle": "ScheduleSceneItem_CharactersTitle__2EgBc",
	"Item": "ScheduleSceneItem_Item__3Wh1N",
	"ItemNu": "ScheduleSceneItem_ItemNu__2x_Vh",
	"Italic": "ScheduleSceneItem_Italic__oe_FI"
};
export default ___CSS_LOADER_EXPORT___;
