// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Weather_Box__2J2rU {\n    padding: 10px;\n}\n\n.Weather_WhiteAqua__1xtJQ {\n  background-color: #f6fffe;\n}\n\n\n.Weather_Input__3YBtD {\n    width: 30%;\n    margin: auto;\n    padding: 10px;\n    background: #ffffff;\n    border: 1px solid #f0f1f9;\n    box-sizing: border-box;\n    border-radius: 2px;\n    color: #7177a1;\n    text-align: center;\n}\n\n.Weather_Input__3YBtD:focus {\n    outline: none;\n}\n\n.Weather_Bold__3JuuC { \n    font-weight: 700;\n  }\n\n/* .Input {\n    background: #ffffff;\n    border: 1px solid #f0f1f9;\n    box-sizing: border-box;\n    border-radius: 2px;\n    color: #7177a1;\n    padding: 0px 10px;\n    height: 30px !important;\n    text-align: center;\n  } */\n\n  .Weather_SubTitle__3nMDa {\n    background-color: #e8f0f1;\n    font-weight: 700;\n  }", "",{"version":3,"sources":["webpack://src/styles/Weather.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;EAClB;;AAEF;;;;;;;;;KASK;;EAEH;IACE,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".Box {\n    padding: 10px;\n}\n\n.WhiteAqua {\n  background-color: #f6fffe;\n}\n\n\n.Input {\n    width: 30%;\n    margin: auto;\n    padding: 10px;\n    background: #ffffff;\n    border: 1px solid #f0f1f9;\n    box-sizing: border-box;\n    border-radius: 2px;\n    color: #7177a1;\n    text-align: center;\n}\n\n.Input:focus {\n    outline: none;\n}\n\n.Bold { \n    font-weight: 700;\n  }\n\n/* .Input {\n    background: #ffffff;\n    border: 1px solid #f0f1f9;\n    box-sizing: border-box;\n    border-radius: 2px;\n    color: #7177a1;\n    padding: 0px 10px;\n    height: 30px !important;\n    text-align: center;\n  } */\n\n  .SubTitle {\n    background-color: #e8f0f1;\n    font-weight: 700;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Box": "Weather_Box__2J2rU",
	"WhiteAqua": "Weather_WhiteAqua__1xtJQ",
	"Input": "Weather_Input__3YBtD",
	"Bold": "Weather_Bold__3JuuC",
	"SubTitle": "Weather_SubTitle__3nMDa"
};
export default ___CSS_LOADER_EXPORT___;
